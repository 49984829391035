document.addEventListener("turbolinks:load", function() {
  $("input:checkbox").on('click', function() {
    var $box = $(this);
    if ($box.is(":checked")) {
      var group = "input:checkbox[name='" + $box.attr("name") + "']";
      $(group).prop("checked", false);
      $box.prop("checked", true);
      var input_value  = document.getElementById(`user_answers_attributes_${$box.data('index')}_value_answers`);
      input_value.value = $box.val();
      input_value.removeAttribute('required')

    } else {
      $box.prop("checked", false);
    }
  });
  var button_next  = document.getElementById('next');
  $(button_next).on('click', function() {
    var get_seccion_id = button_next.dataset.button;
    var seccion_id = parseInt(get_seccion_id) + 1;
    var is_last_seccion = document.getElementById('last');
    var value =  seccion_id.toString();
    var old_seccion = document.querySelectorAll(`[data-seccion='${get_seccion_id}']`);
    for(i =0; i< old_seccion.length; i++){
      var all_inputs = document.getElementsByClassName(get_seccion_id);
      for(j =0; j< all_inputs.length; j++){
        if(all_inputs[j].value == ""){
          var msj_error  = document.getElementById('error');
          msj_error.classList.remove('hidden');
          return;
        }
      }
      old_seccion[i].classList.add('hidden');
    }
    var next_seccion  = document.querySelectorAll(`[data-seccion='${value}']`);
    var msj_error  = document.getElementById('error');
    msj_error.classList.add('hidden');
    for(i =0; i< next_seccion.length; i++){
      next_seccion[i].classList.remove('hidden');
    }
    if(seccion_id == is_last_seccion.dataset.last){
      button_next.classList.add('hidden');
      var button_send =  document.getElementById('btn-submit');
      button_send.classList.remove('hidden');
    }else{
      button_next.dataset.button = seccion_id;
    }
    
  });
  var btn_submit = document.getElementById('btn-submit');
  $(btn_submit).on('click', function(){
    var final_section = btn_submit.dataset.final;
    var all_inputs = document.getElementsByClassName(final_section);
    for(j =0; j< all_inputs.length; j++){
      if(all_inputs[j].value == ""){
        var msj_error  = document.getElementById('error');
        msj_error.classList.remove('hidden');
        return;
      }
    }
  });
  
});
